
import type { PropType } from 'vue'
import { markRaw, watch, onUnmounted, defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { WebWidgetSection } from '@/definitions/chats/settings/web-widget/types'
import useWidget from '@/compositions/chatWidget'
import WidgetDisabledAlert from '@/components/views/chats/webWidget/channels/WidgetDisabledAlert.vue'

export default defineComponent({
  components: {
    TmButton,
  },
  props: {
    items: {
      type: Array as PropType<WebWidgetSection[]>,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    isVisible: {
      type: Boolean,
    },
  },
  setup(props) {
    const activeItem = ref<WebWidgetSection | null>(null)
    const {
      updateCurrentState,
      alertComponent,
    } = useWidget()

    const reset = () => {
      activeItem.value = null
      updateCurrentState('welcome')

      if (alertComponent.value !== markRaw(WidgetDisabledAlert)) {
        alertComponent.value = null
      }
    }

    const setActive = (item: WebWidgetSection) => {
      activeItem.value = item
      updateCurrentState(item.widgetView)
    }
    onUnmounted(() => {
      reset()
    })

    watch(() => props.isVisible, (newVal) => {
      if (!newVal) {
        if (alertComponent.value !== markRaw(WidgetDisabledAlert)) {
          alertComponent.value = null
        }
        setTimeout(() => reset(), 500)
      }
    })

    return {
      setActive,
      activeItem,
      reset,
    }
  },
})
